import type { MaybeRef } from 'vue';

export function joinPageTitle(...titles: string[]) {
  return titles.filter(Boolean).join(' | ');
}

export function usePageTitle(title: MaybeRef<string>) {
  useSeoMeta({
    title,
  });
}
