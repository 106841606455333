<script setup lang="ts">
const logo404 = '404';
</script>

<template lang="pug">
.flex-center-center.col-16
  UiIconsIcon(:name="logo404")
  .title.typ-20-28-600 {{ $t('block.error.404.base.title') }}
  .secondary.typ-14-18-500 {{ $t('block.error.404.base.desc') }}
</template>
