import {
  init,
  addIntegration,
  captureConsoleIntegration,
  extraErrorDataIntegration,
  httpClientIntegration,
  browserTracingIntegration,
} from '@sentry/vue';
import packageJson from '../package.json';
import { getEnvironment } from '@sub/utils/configs';

function lazyLoadSentryIntegrations() {
  // don't load on server
  if (!import.meta.client) return;

  return import('@sentry/vue').then((lazyLoadedSentry) => {
    if (!lazyLoadedSentry) {
      logError('lazyLoadedSentry empty');
      return;
    }
    addIntegration(lazyLoadedSentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }));
  });
}

function getSentryIntegrations() {
  // don't load on server
  if (!import.meta.client) return [
    captureConsoleIntegration({ levels: ['error'] }),
    httpClientIntegration(),
  ];

  const router = useRouter();
  const browserTracing = browserTracingIntegration({ router });

  return [
    browserTracing,
    extraErrorDataIntegration(),
    captureConsoleIntegration({ levels: ['error'] }),
    httpClientIntegration(),
  ];
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;

    init({
      app: vueApp,
      enabled: process.env.NODE_ENV !== 'development',
      maxValueLength: 10000,
      normalizeDepth: 5,
      environment: getEnvironment(),
      release: packageJson.version,
      dsn: 'https://8f71dc8e7af4b1f3152ea076a2727897@o915870.ingest.us.sentry.io/4507228660498432',
      integrations: getSentryIntegrations(),
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
      tracesSampleRate: 0.1,
      profilesSampleRate: 0.1,
    });

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations();
  }
});