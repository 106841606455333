<script setup lang="ts">
import InlineSvg from 'vue-inline-svg';

const props = defineProps<{
  name: string;
}>();

const isPlatform = useRuntimeConfig().public.PLATFORM;
</script>

<template lang="pug">
InlineSvg.flex-shrink-0(v-if="name" :src="`${isPlatform ? '/new' : ''}/svg/${name}.svg`")
</template>