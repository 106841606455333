import type { ICompany, IFile } from '@types';

export function isFileLogo(file: IFile) {
  return file.tag === 'logo';
}

export function isFilePhoto(file: IFile) {
  return file.tag === 'photo';
}

export function getLogoFile(company: ICompany) {
  return company.files?.find(isFileLogo);
}

export function getFilesWithoutLogo(company: ICompany) {
  return company.files?.filter(f => !isFileLogo(f)) || [];
}

export function getPhotos(company: ICompany) {
  return company.files?.filter(isFilePhoto) || [];
}