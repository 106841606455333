import revive_payload_client_1Hb95reXrk from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__gkiwp7nxbz5dlp5ekplr6wvi3m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hD4PX2sHSg from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__gkiwp7nxbz5dlp5ekplr6wvi3m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9rtcW2CkRJ from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__gkiwp7nxbz5dlp5ekplr6wvi3m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_qZNmb1CAD8 from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.5_vue@3.5.10_typescript@5.6.2__webpack-sources@3.2.3/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_tbUvmOSWdP from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__gkiwp7nxbz5dlp5ekplr6wvi3m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2hTpMDdhOa from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__gkiwp7nxbz5dlp5ekplr6wvi3m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dHxXAXpG3E from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__gkiwp7nxbz5dlp5ekplr6wvi3m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_dC74ZXjyfs from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__gkiwp7nxbz5dlp5ekplr6wvi3m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_65mNSTBycR from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.5_typescript@5.6.2_vue@3.5.10_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/cue-subclient/.nuxt/components.plugin.mjs";
import prefetch_client_Z81IWsdcCi from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__gkiwp7nxbz5dlp5ekplr6wvi3m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_qnjSexluQI from "/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_Q1PR2NLLGq from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.0.2_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.5_typescript@5_ixu5zaud2i4w2rosxih6lclopq/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_KdRnV8KE9l from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.5_vue@3.5.10_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_OTV3jYy5Az from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.5_vue@3.5.10_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import unocss_MzCDxu9LMj from "/app/apps/cue-subclient/.nuxt/unocss.mjs";
import analytics_SVnwK7EMrr from "/app/apps/cue-subclient/plugins/analytics.ts";
import error_handler_kEP5FliEXj from "/app/apps/cue-subclient/plugins/error-handler.ts";
import sentry_3AyO8nEfhE from "/app/apps/cue-subclient/plugins/sentry.ts";
export default [
  revive_payload_client_1Hb95reXrk,
  unhead_hD4PX2sHSg,
  router_9rtcW2CkRJ,
  _0_siteConfig_qZNmb1CAD8,
  payload_client_tbUvmOSWdP,
  navigation_repaint_client_2hTpMDdhOa,
  check_outdated_build_client_dHxXAXpG3E,
  chunk_reload_client_dC74ZXjyfs,
  plugin_vue3_65mNSTBycR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Z81IWsdcCi,
  plugin_client_qnjSexluQI,
  plugin_Q1PR2NLLGq,
  switch_locale_path_ssr_KdRnV8KE9l,
  i18n_OTV3jYy5Az,
  unocss_MzCDxu9LMj,
  analytics_SVnwK7EMrr,
  error_handler_kEP5FliEXj,
  sentry_3AyO8nEfhE
]