import { captureException, captureMessage } from '@sentry/vue';

export function logError(exception: any, hint?: Parameters<typeof captureException>[1]) {
  console.error(JSON.stringify(exception));
  captureException(exception, hint);
}

export function logMessage(message: string, context?: Parameters<typeof captureMessage>[1]) {
  captureMessage(message, context);
}
