import type { IService } from '@types';
import type { RuntimeConfig } from '@nuxt/schema';

let config: RuntimeConfig;
function getConfig() {
  if (!config) config = useRuntimeConfig();
  return config;
}


export function getImageUrl(path?: string, ) {
  return path ? `${getConfig().public.S3_URL}/qbp_photos/${path}` : '';
}

export function getServiceImages(service: IService) {
  return service.files?.map(f => getImageUrl(f.fullPath)) || [];
}
