import { default as _404OE9okV3HYkMeta } from "/app/apps/cue-subclient/pages/404.vue?macro=true";
import { default as _91oid_93A7oNt4LZg3Meta } from "/app/apps/cue-subclient/pages/booking/[id]/[oid].vue?macro=true";
import { default as detailsNxRAZWESaMMeta } from "/app/apps/cue-subclient/pages/booking/[id]/details.vue?macro=true";
import { default as indexpiW6QSCRAWMeta } from "/app/apps/cue-subclient/pages/booking/[id]/index.vue?macro=true";
import { default as orderpd650sZR4tMeta } from "/app/apps/cue-subclient/pages/booking/[id]/order.vue?macro=true";
import { default as targetx36GYpsjMsMeta } from "/app/apps/cue-subclient/pages/booking/[id]/target.vue?macro=true";
import { default as indexpqyCka50lbMeta } from "/app/apps/cue-subclient/pages/index.vue?macro=true";
import { default as badaM4kxhLxALMeta } from "/app/apps/cue-subclient/pages/orders/[id]/bad.vue?macro=true";
import { default as successZje3vPnKt0Meta } from "/app/apps/cue-subclient/pages/orders/[id]/success.vue?macro=true";
import { default as _91id_93jOD0VQtEAVMeta } from "/app/apps/cue-subclient/pages/services/[id].vue?macro=true";
import { default as indexmHCnWpmofAMeta } from "/app/apps/cue-subclient/pages/services/index.vue?macro=true";
import { default as component_45stubotOXaKzY4jMeta } from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__gkiwp7nxbz5dlp5ekplr6wvi3m/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubotOXaKzY4j } from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__gkiwp7nxbz5dlp5ekplr6wvi3m/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/app/apps/cue-subclient/pages/404.vue")
  },
  {
    name: "booking-id-oid",
    path: "/booking/:id()/:oid()",
    component: () => import("/app/apps/cue-subclient/pages/booking/[id]/[oid].vue")
  },
  {
    name: "booking-id-details",
    path: "/booking/:id()/details",
    component: () => import("/app/apps/cue-subclient/pages/booking/[id]/details.vue")
  },
  {
    name: "booking-id",
    path: "/booking/:id()",
    meta: indexpiW6QSCRAWMeta || {},
    component: () => import("/app/apps/cue-subclient/pages/booking/[id]/index.vue")
  },
  {
    name: "booking-id-order",
    path: "/booking/:id()/order",
    component: () => import("/app/apps/cue-subclient/pages/booking/[id]/order.vue")
  },
  {
    name: "booking-id-target",
    path: "/booking/:id()/target",
    component: () => import("/app/apps/cue-subclient/pages/booking/[id]/target.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/apps/cue-subclient/pages/index.vue")
  },
  {
    name: "orders-id-bad",
    path: "/orders/:id()/bad",
    component: () => import("/app/apps/cue-subclient/pages/orders/[id]/bad.vue")
  },
  {
    name: "orders-id-success",
    path: "/orders/:id()/success",
    component: () => import("/app/apps/cue-subclient/pages/orders/[id]/success.vue")
  },
  {
    name: "services-id",
    path: "/services/:id()",
    meta: _91id_93jOD0VQtEAVMeta || {},
    component: () => import("/app/apps/cue-subclient/pages/services/[id].vue")
  },
  {
    name: "services",
    path: "/services",
    meta: indexmHCnWpmofAMeta || {},
    component: () => import("/app/apps/cue-subclient/pages/services/index.vue")
  },
  {
    name: component_45stubotOXaKzY4jMeta?.name,
    path: "/nav",
    component: component_45stubotOXaKzY4j
  },
  {
    name: component_45stubotOXaKzY4jMeta?.name,
    path: "/services/info/:pathMatch(.*)",
    component: component_45stubotOXaKzY4j
  },
  {
    name: component_45stubotOXaKzY4jMeta?.name,
    path: "/booking",
    component: component_45stubotOXaKzY4j
  },
  {
    name: component_45stubotOXaKzY4jMeta?.name,
    path: "/booking/:pathMatch(.*)/order",
    component: component_45stubotOXaKzY4j
  },
  {
    name: component_45stubotOXaKzY4jMeta?.name,
    path: "/booking/:pathMatch(.*)/details",
    component: component_45stubotOXaKzY4j
  }
]