<script setup lang="ts">
import { usePageTitle } from '@sub/utils/usePageSettings';
import Error404 from '@sub/components/Error404.vue';

usePageTitle('404');
</script>

<template lang="pug">
NuxtLayout(name="error")
  Error404.h-100p
</template>
