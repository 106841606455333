export function get3DomainName() {
  const origin = useRequestURL().origin;

  if (origin.includes('localhost') || origin.includes(':3020') || origin.includes(':8020')) {
    return useRuntimeConfig().public.DEFAULT_DOMAIN;
  }

  const url = new URL(origin);
  const domainNames = url.hostname.split('.').reverse();

  return domainNames[Math.min(domainNames.length - 1, 2)] || '';
}